<template>
  <section class=" h-6/12">
    <div class="px-2 sm:px-10 container">
      <div class="">
        <page-sucess />
      </div>
    </div>
  </section>
</template>

<script>
import PageSucess from '@/components/PageSucess.vue';

export default {
  components: {
    PageSucess,
  },
};
</script>

<style scoped>



/* ESTILIZAÇÃO BÁSICA */
.font-h1 {
  font-size: 170%;
}

.font-h2 {
  font-size: 120%;
}

.font-h3 {
  font-size: 100%;
}

.font-h4 {
  font-size: 75%;
}
.font-h5 {
  font-size: 70%;
}

.font-h6 {
  font-size: 50%;
}

p {
  font-size: 90%;
}

.contrast .bg-gray-200 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrastNegative .bg-gray-200 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrast .bg-sky-500 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.contrastNegative .bg-sky-500 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.contrast .bg-custom-blue1 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.contrastNegative .bg-custom-blue1 {
  background: rgba(255, 255, 255, 0.1) !important;
}

hr.rounded-t {
  border-top-left-radius: 0px !important /* 4px */;
}
</style>