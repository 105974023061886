<template>
  <section class="p-10" id="conteudo-1">
    <div class="py-10 bg-image-blue shadow-xl rounded-2xl text-white transition-all">
      <i id="failed" class="fa fa-frown-o iconFailed rounded-full text-red-700 bg-red-100" aria-hidden="true"></i>

      <br />

      <!-- <h1 class="font-bold uppercase text-5xl">Alerta</h1> -->

      <br />

      <div
        class="bg-red-100 uppercase rounded-lg mx-6 py-5 px-6 mb-3  text-red-700 inline-flex items-center "
        role="alert"
      >

        <i class="fa fa-times-circle w-4 h-4 sm:mr-2 fill-current hidden sm:block" aria-hidden="true"></i>
        Desculpa seu cadastro não foi registrando, chegamos ao limite de registros!
      </div>

      <br />

      <div>
        Para voltar a tela anterior,
        <router-link
          to="/"
          class="transition-all duration-300 font-bold border-2 border-transparent hover:border-red-600 hover:shadow-lg px-2 hover:rounded-xl hover:bg-red-600  animate-pulse hover:animate-none"
          >clique aqui.</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.iconFailed {
  font-size: 150px;
}

.bg-image-blue {
  background-image: url('@/assets/pattern-dark.png');
}

#failed {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  50% {transform: rotate(20deg);}
}
</style>