<template>
  <div class="bg-image-blue mt-6 py-6  rounded-xl" id="conteudo-1">
    <div class="pt-0">
      <h1
        class="mx-10 text-left text-xl font-bold text-custom-gray"
        v-if="inscricao.inscricoes"
      >
        Total de Registro(s): {{ inscricao.inscricoes.length }}
      </h1>

      <h1 class="mx-auto text-xl font-bold text-custom-gray text-center" v-else>
        <div class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-gray-300 m-20 " role="status">
        <p class="visually-hidden">Loading...</p>
        </div>
      </h1>
    </div>

    <br />

    <div class="mx-10 overflow-auto transition-all duration-300" style="height: 530px" v-if="inscricao.inscricoes">
      <table class="m-auto hidden lg:block text-gray-100">
        <tr class=" border-black border-4 md:border-2 bg-custom-blue1">
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            ID:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Nome:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Endereço:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Grupo, Movimento Social ou Instituição:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Área de Atuação:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Nome do Governo:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Conselheiro:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Telefone:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            E-mail:
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-4 capitalize">
            Criado / Atualizado
          </th>
        </tr>
        <tr
          v-for="{
            id,
            nome,
            endereco,
            m_social,
            m_social_nome,
            area_atuacao,
            area_atuacao_nome,
            s_governo,
            s_governo_nome,
            conselheiro,
            conselheiro_nome,
            telefone,
            email,
            created_at,
            updated_at,
          } in inscricao.inscricoes"
          :key="{
            id,
            nome,
            endereco,
            m_social,
            m_social_nome,
            area_atuacao,
            area_atuacao_nome,
            s_governo,
            s_governo_nome,
            conselheiro,
            conselheiro_nome,
            telefone,
            email,
            created_at,
            updated_at,
          }"
          class="border-black border-4 md:border-2"
        >
          <th class="border-black border-r-4 md:border-r-2 py-5 px-6">
            <h1 class="text-md">{{ id }}</h1>
          </th>
          <th
            class="border-black border-r-4 md:border-r-2 py-5 px-2 capitalize"
          >
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md">{{ nome }}</h1>
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <h1 class="invisible">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="endereco != ''">{{ endereco }}</h1>
            <h1 class="text-md" v-else>Não Inserido</h1>
            <h1 class="invisible">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th
            class="border-black border-r-4 md:border-r-2 py-5 capitalize"
          >
            <h1 class="invisible">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="m_social == 'S'">{{ m_social_nome }}</h1>
            <h1 v-else>Não possui</h1>
            <h1 class="invisible">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="area_atuacao == 'Duque de Caxias' && !area_atuacao_nome">{{area_atuacao}}</h1>
            <h1 class="text-md" v-else-if="area_atuacao_nome">{{area_atuacao_nome}}</h1>
            <h1 class="text-md" v-else-if="area_atuacao_nome != '' && area_atuacao === 'Duque de Caxias'">{{area_atuacao_nome}}</h1>
            <h1 class="text-md" v-else>Não Inserido</h1>
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="s_governo == 'S'">{{s_governo_nome}}</h1>
            <h1 class="text-md" v-else>Não Pertence</h1>
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="conselheiro == 'S' && conselheiro_nome">{{conselheiro_nome}}</h1>
            <h1 class="text-md" v-else-if="conselheiro == 'N'">Não Inserido</h1>
            <h1 class="text-md" v-else>Não Inserido</h1>
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="telefone">{{telefone}}</h1>
            <h1 class="text-md" v-else>Não Inserido</h1>
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <h1 class="text-md" v-if="email != ''">{{email}}</h1>
            <h1 class="text-md" v-else>Não Inserido</h1>
          </th>
          <th class="border-black border-r-4 md:border-r-2 py-5 px-2">
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
            <h1 class="text-md" v-if="created_at != '' && updated_at != ''">{{created_at}} / {{updated_at}}</h1>
            <!-- Colocando Espaço -->
            <h1 class="invisible text-sm">InserindoTextoParaOcuparMaisEspaço</h1>
          </th>
        </tr>
      </table>

      <div
        v-for="{
            id,
            nome,
            endereco,
            m_social,
            m_social_nome,
            area_atuacao,
            area_atuacao_nome,
            s_governo,
            s_governo_nome,
            conselheiro,
            conselheiro_nome,
            telefone,
            email,
            created_at,
            updated_at,
          } in inscricao.inscricoes"
          :key="{
            id,
            nome,
            endereco,
            m_social,
            m_social_nome,
            area_atuacao,
            area_atuacao_nome,
            s_governo,
            s_governo_nome,
            conselheiro,
            conselheiro_nome,
            telefone,
            email,
            created_at,
            updated_at,
          }"
        class="lg:hidden overflow-auto mb-6"
      >
        <table class="mobile">
          <tr class="mobile">
            <th class="mobile">ID:</th>
            <td class="mobile">{{id}}</td>
          </tr>

          <tr class="mobile">
            <th class="mobile">Nome:</th>
            <td class="mobile" v-if="nome == '' || nome == ' '">Não Inserido</td>
            <td class="mobile" v-else>{{nome}}</td>
          </tr>

          <tr class="mobile">
            <th class="mobile">Endereço:</th>
            <td class="mobile" v-if="nome == '' || nome == ' '">{{nome}}</td>
            <td class="mobile">{{endereco}}</td>
          </tr>

          <tr class="mobile">
            <th class="mobile">Grupo, Mov. Social ou Inst.:</th>
            <td class="mobile" v-if="m_social == 'S'">{{ m_social_nome }}</td>
            <td class="mobile" v-else>Não possui</td>
          </tr>

          <tr class="mobile">
            <th class="mobile">Área de Atuação:</th>
            <td class="mobile" v-if="area_atuacao == 'Duque de Caxias' && !area_atuacao_nome">{{area_atuacao}}</td>
            <td class="mobile" v-else-if="area_atuacao_nome">{{area_atuacao_nome}}</td>
            <td class="mobile" v-else-if="area_atuacao_nome != '' && area_atuacao === 'Duque de Caxias'">{{area_atuacao_nome}}</td>
            <td class="mobile" v-else>Não Inserido</td>
          </tr>

          <tr class="mobile">
            <th class="mobile">Governo:</th>
            <td class="mobile" v-if="s_governo == 'S'">{{s_governo_nome}}</td>
            <td class="mobile" v-else>Não Pertence</td>
          </tr>

          <tr class="mobile">
            <th class="mobile">Conselheiro:</th>
            <td class="mobile" v-if="conselheiro == 'S'">{{conselheiro_nome}}</td>
            <td class="mobile" v-else>Não</td>
          </tr>
          <tr class="mobile">
            <th class="mobile">Telefone:</th>
            <td class="mobile" v-if="telefone != ''">{{telefone}}</td>
            <td class="mobile" v-else>Não Inserido</td>
          </tr>
          <tr class="mobile ">
            <th class="mobile">E-mail:</th>
            <td class="mobile " v-if="email != ''">{{email}}</td>
            <td class="mobile" v-else>Não Inserido</td>
          </tr>
          <tr class="mobile">
            <th class="mobile">Criado:</th>
            <td class="mobile " v-if="created_at">{{created_at}}</td>
            <td class="mobile" v-else>Não Inserido</td>
          </tr>
          <tr class="mobile">
            <th class="mobile">Atualizado:</th>
            <td class="mobile " v-if="updated_at">{{updated_at}}</td>
            <td class="mobile" v-else>Não Inserido</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableApi',
  data() {
    return {
      inscricao: [],
      total: '',
    };
  },
  methods: {
    puxarApi() {
      fetch(
        'https://api.pedalnoparaiso.duquedecaxias.rj.gov.br/inscricao_consea_dc_all',
      )
        .then((response) => response.json())
        .then((json) => {
          this.inscricao = json;
        });
    },
  },
  watch: {
    url() {
      this.puxarApi();
    },
  },
  created() {
    console.log(this.puxarApi());
    this.puxarApi();
  },
};
</script>

<style scoped>
.bg-image-blue {
  background-image: url('@/assets/pattern-dark.png');
}

table.mobile {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(even) {
  background: rgba(0, 0, 0, 0.08);
}

tr:nth-of-type(odd) {
  background: rgba(0, 0, 0, 0.25);
}

tr:nth-of-type(even):hover {
  background: rgba(0, 0, 0, 0.5);
}

tr:nth-of-type(odd):hover {
  background: rgba(0, 0, 0, 0.5);
}

/* CONTRAST */

.contrast tr:nth-of-type(even),
.contrastNegative tr:nth-of-type(even) {
  background: rgba(255, 255, 255, 0.04);
}

.contrast tr:nth-of-type(odd),
.contrastNegative tr:nth-of-type(odd) {
  background: rgba(255, 255, 255, 0.14);
}

.contrast tr:nth-of-type(even):hover,
.contrastNegative tr:nth-of-type(even):hover {
  background: rgba(255, 255, 255, 0.21);
}

.contrast tr:nth-of-type(odd):hover,
.contrastNegative tr:nth-of-type(odd):hover {
  background: rgba(255, 255, 255, 0.21);
}

.contrast .bg-custom-blue1,
.contrastNegative .bg-custom-blue1 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrastNegative .bg-custom-blue1 {
  color: yellow !important;
}

/* FIM DO CONSTRAST */

th.mobile {
  background: var(--bg-custom-blue1);
  font-weight: bold;
}
.contrast th.mobile,
.contrastNegative th.mobile {
  background: rgba(255, 255, 255, 0.3);
  font-weight: bold;
}

td.mobile,
th.mobile {
  padding: 6px;
  border: 1px solid #000;
  color: white;
  text-align: left;
}
</style>
