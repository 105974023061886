import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PageSucessView from '../views/PageSucessView.vue';
import PageFailedView from '../views/PageFailedView.vue';
import TableApiView from '../views/TableApiView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/sucesso',
    name: 'sucesso',
    component: PageSucessView,
  },
  {
    path: '/falha',
    name: 'falha',
    component: PageFailedView,
  },
  {
    path: '/tabela',
    name: 'tableapi',
    component: TableApiView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
