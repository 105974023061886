<template>
  <section class="p-10" id="conteudo-1">
    <div class="py-10 bg-image-blue shadow-xl rounded-2xl text-white transition-all">
      <i
        class="fa fa-check-circle-o iconSucess rounded-full bg-green-100 text-green-700"
        aria-hidden="true" id="sucess"
      ></i>

      <br />

      <br />

      <div
        class="bg-green-100 uppercase rounded-lg mx-6 py-5 px-6 mb-3  text-green-700 inline-flex items-center "
        role="alert"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="check-circle"
          class="w-4 h-4 sm:mr-2 fill-current hidden sm:block"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
          ></path>
        </svg>
        Incrição realizada com sucesso!
      </div>

      <br />

      <div>
        Para voltar a tela anterior,
        <router-link
          to="/"
          class="transition-all duration-300 font-bold border-2 border-transparent hover:border-green-600 hover:shadow-lg px-2 hover:rounded-xl hover:bg-green-600  animate-pulse hover:animate-none"
          >clique aqui.</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.iconSucess {
  font-size: 150px;
}

.bg-image-blue {
  background-image: url('@/assets/pattern-dark.png');
}

#sucess {
  animation: mymove 2s infinite;
}

@keyframes mymove {
  50% {transform: rotate(20deg);}
}
</style>