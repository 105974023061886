<template>
  <ColSec>
    <TitleHead class="leading-10">
      11º Conferência Municipal de Segurança Alimentar e Nutricional Sustentável
      de Duque de Caxias
    </TitleHead>

    <BackgroundGray>
      <CardSec>
        <strong>Data:</strong> 06 de agosto de 2022 - 8 às 17 horas. <br>
        <strong>Local:</strong> Escola Municipal Expedicionário Aquino de Araújo.
      </CardSec>
      <CardSec>
        
        <h2 class="font-h1 text-center font-bold mt-3 uppercase">Formulário de Inscrição</h2>
        <form @submit.prevent="submitForm" style="text-align: left">
          <div class="relative z-0 mb-6 mt-3 w-full group pt-2">
            <input
              type="text"
              name="nome"
              class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              v-model="form.nome"
              required
            />
            <label
              for="nome"
              class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Nome completo:</label
            >
          </div>
          <div class="relative z-0 mb-8 w-full group pt-2">
            <input
              type="text"
              name="endereco"
              class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              v-model="form.endereco"
              required
            />
            <label
              for="endereco"
              class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Endereço:</label
            >
          </div>
          <div class="grid xl:grid-cols-2 mb-3 xl:gap-6">
            <div class="relative z-0 mb-6 w-full group pt-2">
              <div class="">
                <label
                  class="font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-100 top-3 -z-10 origin-[0] peer-focus:left-0"
                  >Pertence a algum Grupo, Movimento Social ou
                  Instituição?</label
                >
              </div>
              <div class="flex">
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="m_social"
                    id="radioYesGroup"
                    value="S"
                    v-model="form.m_social"
                    required
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="radioYesGroup"
                    >Sim</label
                  >
                </div>
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mr-0"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="m_social"
                    id="radioNoGroup"
                    v-model="form.m_social"
                    value="N"
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="radioNoGroup"
                    >Não</label
                  >
                </div>
              </div>
            </div>
            <div class="relative z-0 mb-6 w-full group pt-2 md:mt-6">
              <input
                type="text"
                name="m_social_nome"
                id="m_social_nome"
                class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                v-model="form.m_social_nome"
              />
              <label
                for="m_social_nome"
                class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Se sim, qual?</label
              >
            </div>
          </div>
          <div class="grid xl:grid-cols-2 mb-3 xl:gap-6">
            <div class="relative z-0 mb-6 w-full group pt-2">
              <div class="">
                <label
                  class="font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-100 top-3 -z-10 origin-[0] peer-focus:left-0"
                  >Área de Atuação:</label
                >
              </div>
              <div class="flex">
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mr-0"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="area_atuacao"
                    id="radioPMDC"
                    value="Duque de Caxias"
                    v-model="form.area_atuacao"
                    required
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="radioPMDC"
                    >Duque de Caxias</label
                  >
                </div>
              </div>
            </div>
            <div
              class="relative z-0 mb-6 w-full group mt-2 grid grid-cols-1 sm:grid-cols-5 gap-3"
            >
              <div
                class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative z-0 sm:mt-4"
              >
                <input
                  class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="area_atuacao"
                  id="radiosOthers"
                  v-model="form.area_atuacao"
                  value="outros"
                />
                <label
                  class="font-h3 form-check-label inline-block text-gray-800"
                  for="radiosOthers"
                  >Outros</label
                >
              </div>

              <div class="relative pt-2 z-0 w-full mt-4 sm:col-span-4">
                <input
                  type="text"
                  name="area_atuacao_nome"
                  id="area_atuacao_nome"
                  class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  v-model="form.area_atuacao_nome"
                />
                <label
                  for="area_atuacao_nome"
                  class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Onde?</label
                >
              </div>
            </div>
          </div>
          <div class="grid xl:grid-cols-2 mb-3 xl:gap-6">
            <div class="relative z-0 mb-6 w-full group pt-2">
              <div class="">
                <label
                  class="font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-100 top-3 -z-10 origin-[0] peer-focus:left-0"
                  >Pertence a algum Órgão ou Secretaria de Governo?</label
                >
              </div>
              <div class="flex">
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="s_governo"
                    id="radioYesOrg"
                    value="S"
                    v-model="form.s_governo"
                    required
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="radioYesOrg"
                    >Sim</label
                  >
                </div>
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mr-0"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="s_governo"
                    id="radioNoOrg"
                    v-model="form.s_governo"
                    value="N"
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="radioNoOrg"
                    >Não</label
                  >
                </div>
              </div>
            </div>
            <div class="relative z-0 mb-6 w-full group pt-2 sm:mt-6">
              <input
                type="text"
                name="s_governo_nome"
                id="s_governo_nome"
                class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                v-model="form.s_governo_nome"
              />
              <label
                for="s_governo_nome"
                class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Se sim, qual?</label
              >
            </div>
          </div>
          <div class="grid xl:grid-cols-2 xl:gap-6">
            <div class="relative z-0 mb-6 w-full group pt-2">
              <div class="">
                <label
                  class="font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-100 top-3 -z-10 origin-[0] peer-focus:left-0"
                  >É Conselheiro de algum Conselho de Direitos?</label
                >
              </div>
              <div class="flex">
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="conselheiro"
                    id="inlineRadio10"
                    value="S"
                    v-model="form.conselheiro"
                    required
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="inlineRadio10"
                    >Sim</label
                  >
                </div>
                <div
                  class="form-check form-check-inline block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer mr-0"
                >
                  <input
                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="conselheiro"
                    id="inlineRadio20"
                    v-model="form.conselheiro"
                    value="N"
                  />
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="inlineRadio20"
                    >Não</label
                  >
                </div>
              </div>
            </div>
            <div class="relative z-0 mb-6 w-full group pt-2 sm:mt-6">
              <input
                type="text"
                name="conselheiro_nome"
                id="conselheiro_nome"
                class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                v-model="form.conselheiro_nome"
              />
              <label
                for="conselheiro_nome"
                class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Se sim, qual?</label
              >
            </div>
          </div>
          <div class="grid xl:grid-cols-2 xl:gap-6">
            <div class="relative z-0 mb-6 w-full group pt-2">
              <input
                type="tel"
                name="telefone"
                onkeypress="mask(this, mphone);"
                onblur="mask(this, mphone);"
                id="telefone"
                class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                v-model="form.telefone"
                required
              />
              <label
                for="telefone"
                class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Telefone:</label
              >
            </div>
            <div class="relative z-0 mb-6 w-full group pt-2">
              <input
                type="email"
                name="email"
                id="email"
                class="block py-2.5 px-0 w-full font-h3 text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                v-model="form.email"
                required
              />
              <label
                for="email"
                class="absolute font-h3 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >E-mail:</label
              >
            </div>
          </div>

          <div
            class="flex bg-red-100 rounded-lg py-5 px-6 mb-3 text-center text-red-700 items-center w-full"
            role="alert"
          >
            <p>
              <i
                class="fa fa-exclamation-triangle font-h2"
                aria-hidden="true"
              ></i>
              <br />
              Atenção! Verifique que as informações inseridas no formulário
              estão corretas, pois caso não esteja corretamente inserido as
              informações, pode <span class="font-bold">prejudicar</span> no
              momento da inscrição.
            </p>
          </div>

          <button
            v-on:click="submitForm()"
            class="block px-6 py-3 w-full bg-custom-blue2 text-custom-gray gray-400 m-0 hover:opacity-90 hover:underline transition duration-150 ease-in-out rounded-lg menu-bg-text"
          :disabled="btAction" >
            {{btSubmit}} <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </button>
        </form>
      </CardSec>
    </BackgroundGray>
  </ColSec>
</template>

<script>
import ColSec from '@/components/ComponentPag/ColSec.vue';
import TitleHead from '@/components/ComponentSec/TitleHead.vue';
import BackgroundGray from '@/components/ComponentPag/BackgroundGray.vue';
import CardSec from '@/components/ComponentPag/CardSec.vue';
import axios from 'axios';

export default {

  components: {
    ColSec,
    TitleHead,
    BackgroundGray,
    CardSec,
  },

  data() {
      return {
        btSubmit: 'Inscreva-se',
        btAction: false,
        form: {
            nome: '',
            endereco: '',
            m_social: '',
            m_social_nome: '',
            area_atuacao: '',
            area_atuacao_nome: '',
            s_governo: '',
            s_governo_nome: '',
            conselheiro: '',
            conselheiro_nome: '',
            telefone: '',
            email: ''
        }
      
      }
    },

  methods: {
   async submitForm(){
            this.btSubmit = 'Enviando, aguarde...';
            this.btAction = true;            
            try {              
              await axios.post('https://api.pedalnoparaiso.duquedecaxias.rj.gov.br/inscricao_consea_dc', 
              {nome: this.form.nome,
              endereco: this.form.endereco,
              m_social: this.form.m_social,
              m_social_nome: this.form.m_social_nome,
              area_atuacao: this.form.area_atuacao,
              area_atuacao_nome: this.form.area_atuacao_nome,
              s_governo: this.form.s_governo,
              s_governo_nome: this.form.s_governo_nome,
              conselheiro: this.form.conselheiro,
              conselheiro_nome: this.form.conselheiro_nome,
              telefone: this.form.telefone,
              email: this.form.email
              },            
              {
                headers: {
                  // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;application/json Access-Control-Allow-Origin: *',
                },


              })
              .then((response) => {
                // alert('Inscrição realizada com sucesso!');
                this.btSubmit = 'Inscrição realizada com sucesso!';
                this.$router.push("/sucesso")            
                console.log(response);
              }, (error) => {
                if(error.response.status === 401){
                  this.$router.push("/falha")   
                }
                this.btAction = false;
                this.btSubmit = 'Enviar novamente!';
                alert('Erro ao enviar, preencha todos os dados corretamente.');
                console.log(error);
              });
              
            }                
            catch (e) {
        this.errors.push(e)
      }
        }
    }
         

};
</script>

<style scoped>
/* ESTILIZAÇÃO BÁSICA */
.font-h1 {
  font-size: 170%;
}

@media(max-width: 1000px) {
  .font-h1 {
    font-size: 120%;
  }
}

.font-h2 {
  font-size: 120%;
}

.font-h3 {
  font-size: 100%;
}

.font-h4 {
  font-size: 75%;
}
.font-h5 {
  font-size: 70%;
}

.font-h6 {
  font-size: 50%;
}

.contrast .bg-gray-200 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrastNegative .bg-gray-200 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrast .bg-sky-500 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.contrastNegative .bg-sky-500 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.contrast .bg-custom-blue1, .contrast .bg-custom-blue2 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.contrastNegative .bg-custom-blue1, .contrastNegative .bg-custom-blue2 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.dropdown-content {
  max-height: 50px !important;
}

input {
  color: black!important;
}
</style>
